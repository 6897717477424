.ql-editor{
    height:500px;
    font-size:16px;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
p{
  margin-top: 0;
  margin-bottom: 0;
}

.chat{
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  min-height: 60vh;
  max-height: 60vh;
}

.chat h1{
  border-bottom: 1px solid  rgb(163, 207, 187);
  padding: 8px 16px;
  font-size: 16px;
  font-weight: normal;
  background-color: rgb(209, 231, 221);
}

.chatinput{
  border-top: 1px solid #ccc;
  margin: 0 0 0 0;
  border-radius: 0;
}

.chat .chattext{
  overflow-y: auto;
  max-height: 50vh;
}

.chat .chattext p.assistant{
  color: black;
  margin: 10px;
}
.chat .chattext p.user{
  color: #565656;
  text-align: right;
  margin: 10px;
}

.chat .chatloading{
  margin: 10px;
  text-align: center;
}