*{
    box-sizing: content-box;
}
body{
    margin:0;
    font-family: sans-serif;   
}
html, body, #root {
    height: 100%;
    /*background-color: #333;*/
}
.form-signin h1{
    margin:0;
    padding:0;
}
.logo{
    width:150px;
}
.cursor{
    cursor:pointer;
}
.fullpage{ 
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
    /*background-color: #333;
    color:#bbb;*/
}
/*
.fullpage a{
    color: #bbb;
}
.fullpage a:visited{
    color: #bbb;
}
.fullpage a:hover{
    color: #fff;
}*/
.form-signin {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    margin: auto;
    text-align: center;    
    border: 1px solid #aaa;
    
}
.form-signin .form-floating:focus-within {
    z-index: 2;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
.form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    /*background-color: #222 !important;
    color: #bbb;*/
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    /*background-color: #222;
    color: #bbb;*/
}
.form-group{
    color: #ccc;
}
.form-group input{
    /*background-color: #777;*/
    border:0;
    color: white;
}
.form-group textarea{
    /*background-color: #fff;*/
    border:0;
    height:10em;
}